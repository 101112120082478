<template>
      <parent
          :locale="language"
          :parentApi="parentApi"
          :brand="brand"
      />
</template>

<script>
import {useI18n} from "vue-i18n";
import {environment} from "@/config";

export default {
  name: "MyParent",
  data() {
    return {
      language: this.locale,
      parentApi: environment.VUE_APP_PARENT_API,
      brand: process.env.VUE_APP_SUB_PATH,
    }
  },
  setup() {
    const {t, locale} = useI18n()
    return {t, locale}
  }
}

</script>
